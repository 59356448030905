<template>
  <b-card title="Seo">
    <b-tabs>
      <b-tab
      v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='seo-classical-course' ||currentRouteName=='seo-online-course'
          ||currentRouteName=='seo-course-in-city'
        "
        @click="$router.push('/seo-classical-course')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Seo Course</span>
        </template>

      
      </b-tab>
      <b-tab   v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='seo-classical-category' ||currentRouteName=='seo-online-category' "
        @click="$router.push('/seo-classical-category')">
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Seo Category</span>
        </template>
  

      </b-tab>
     
       <b-tab :active="currentRouteName=='seo-city'"
       @click="$router.push('/seo-city')" v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'" >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Seo City</span>
        </template>
   
       <!-- <SeoCity></SeoCity> -->

      </b-tab>
        <b-tab :active="currentRouteName=='seo-category-in-city'" 
        @click="$router.push('/seo-category-in-city')" v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'" >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Seo Category In CIty</span>
        </template>
   
       <!-- <SeoCategryInCity></SeoCategryInCity> -->

      </b-tab>
         <b-tab :active="currentRouteName=='seo-pages'" @click="$router.push('/seo-pages')" v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'" >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Seo Pages</span>
        </template>
   
    <!-- <SeoPages></SeoPages> -->

      </b-tab>
      <b-tab :active="currentRouteName=='seo-blog'" @click="$router.push('/seo-blog')" v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Blogger' || role.roles[0]=='Super Admin'" >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Seo Blog</span>
        </template>
     
        <BlogSeo v-if=" role.roles[0]=='Blogger'  "  />
   
    <!-- <SeoPages></SeoPages> -->

      </b-tab>
     
    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <SeoClassicalCourse v-if="route.name === 'seo-classical-course' && role.roles[0]==='Supervisor' || role.roles[0]=='Super Admin'"  />
        
      <router-view  v-else>
          <component :is="Component" />
        </router-view>
  </router-view>
  
   
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText ,BCard} from 'bootstrap-vue'
import SeoClassicalCourse from './seocourse/SeoClassicalCourse.vue';
import CategorySeo from './seocategory/CategorySeo.vue';
import BlogSeo from './seoblog/BlogSeo.vue';
import SeoCity from './SeoCity.vue';
import SeoCategryInCity from './SeoCategryInCity.vue';
import SeoPages from './SeoPages.vue';



export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    SeoClassicalCourse
    // CategoriesList,
    ,
    CategorySeo,
    BlogSeo,
    SeoCity,
    SeoCategryInCity,
    SeoPages
},
  data() {
    return {
   role:{}
    }
  },
  created(){
    this.role=JSON.parse(localStorage.getItem('userData'))
  }
  ,
   computed: {
    currentRouteName() {
        return this.$route.name;
    }}
}
</script>
